import { template as template_855a008cf9b04b49a1cd2c0f2d88a28f } from "@ember/template-compiler";
const FKText = template_855a008cf9b04b49a1cd2c0f2d88a28f(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
