import { template as template_21305ea8e6f8477b9ccfa29cb6c2c3a3 } from "@ember/template-compiler";
const FKLabel = template_21305ea8e6f8477b9ccfa29cb6c2c3a3(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
